import axiosService from '@/utils/request'

export function postAction (url, parameter) {
  const signHeader = {
    'X-Sign': 'token',
    'X-TIMESTAMP': new Date().getTime()
  }

  return axiosService({
    url: url,
    method: 'post',
    data: parameter,
    headers: signHeader
  })
}

export function httpAction (url, parameter, method) {
  const signHeader = {
    'X-Sign': 'token',
    'X-TIMESTAMP': new Date().getTime()
  }
  return axiosService({
    url: url,
    method: method,
    data: parameter,
    headers: signHeader
  })
}

export function putAction (url, parameter) {
  return axiosService({
    url: url,
    method: 'put',
    data: parameter
  })
}

export function getAction (url, parameter) {
  const signHeader = {
    'X-Sign': 'token',
    'X-TIMESTAMP': new Date().getTime()
  }

  return axiosService({
    url: url,
    method: 'get',
    params: parameter,
    headers: signHeader
  })
}

export function deleteAction (url, parameter) {
  return axiosService({
    url: url,
    method: 'delete',
    params: parameter
  })
}
