import { routes } from '@/router'

export const HeaderMenu = []

const LeftMenu = []

routes.sort((x, y) => {
  return x.name.length - y.name.length
})

for (const routesKey in routes) {
  LeftMenu.push({
    title: routes[routesKey].name,
    key: routes[routesKey].key,
    to: routes[routesKey].path
  })
}

export {
  LeftMenu
}
