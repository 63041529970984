import axios from 'axios'

// 创建请求
const axiosService = axios.create({
  baseURL: '/mock',
  timeout: 60000 // 请求超时时间
})

// 请求拦截器
axiosService.interceptors.request.use(config => {
  return config
}, (error) => {
  return Promise.reject(error)
})

// 相应拦截器
axiosService.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  return Promise.reject(error)
})

export default axiosService
